<template>
  <HourglassLoader :loading="loading">
    <div class="card bg-yellow-alt shadow-sm">
      <div class="card-header mb-3">
        <div class="d-block">
          <div class="d-flex justify-content-between w-100">
            <div class="h5 font-weight-bolder mb-2">Fiat Balances</div>
            <div class="d-flex flex-column flex-md-row ml-auto">
              <a class="btn btn-secondary text-dark btn-sm mr-2" @click="RefreshBalance">
                <span class="fas fa-sync"></span>
                Refresh
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <template v-if="wallet">
          <div class="row">
            <div
              v-for="currency in Object.keys(wallet)"
              :key="currency"
              class="col-12 col-lg-6 col-md-6 col-xl-4 mb-2"
            >
              <div class="card">
                <h4 class="card-header">{{ currency }} Balance</h4>
                <div class="card-body row">
                  <div class="col-12 col-xl-6">
                    <h6>Available Balance</h6>
                    <h4 class="h4">{{ currency }} {{ wallet[currency].available_balance }}</h4>
                  </div>

                  <div class="col-12 col-xl-6">
                    <h6>Ledger Balance</h6>
                    <h4 class="h4">{{ currency }} {{ wallet[currency].ledger_balance }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="error" class="display-2-lg">{{ error.message }}</div>
          <div v-else class="display-2">...</div>
        </template>
      </div>
    </div>
  </HourglassLoader>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { apiGet } from "@/api";
import HourglassLoader from "@/components/loader/HourglassLoader";
import { Toast } from "@/utils/notify";

export default {
  name: "FiatWalletDetails",
  components: { HourglassLoader },
  setup() {
    const loading = ref(false);
    const wallet = ref(null);
    const error = ref(null);
    const onlinePaymentRef = ref(null);
    const REF_paymentModalButton = ref(null);

    const RefreshBalance = async () => {
      loading.value = true;
      try {
        const response = await apiGet("/admin/misc/fiat-balances");
        wallet.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fiat Balances Fetched",
        }).then();
      } catch (e) {
        error.value = e;
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch wallet balance: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshBalance);

    return {
      RefreshBalance,
      wallet,
      error,
      loading,
      onlinePaymentRef,
      REF_paymentModalButton,
    };
  },
};
</script>

<style scoped></style>
