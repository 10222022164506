<template>
  <HourglassLoader :loading="loading">
    <div class="card bg-dark shadow-sm">
      <div class="card-header mb-3 text-white">
        <div class="d-block">
          <div class="d-flex justify-content-between w-100">
            <div class="h5 font-weight-bolder mb-2">Metrics</div>
            <div class="d-flex flex-column flex-md-row ml-auto">
              <a class="btn btn-secondary text-dark btn-sm mr-2" @click="RefreshBalance">
                <span class="fas fa-sync"></span>
                Refresh
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <template v-if="data">
          <div class="row">
            <div
              v-for="(metric, index) in data.metrics"
              :key="index"
              class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
            >
              <div
                class="card"
                :class="{
                  'blue':  index == 0,
                  'green':  index == 1,
                  'orange':  index == 2,
                  'red':  index == 3,
                  'purple':  index == 4,
                }"
              >
                <div class="title">{{ metric.description.replace("Number of", "") }}</div>
                <i class="zmdi zmdi-upload"></i>
                <div class="value">{{ metric.value }}</div>
                <div class="stat">
                  <!-- <b>13</b>% -->
                </div>
              </div>
            </div>

            <!-- <div
              v-for="(metric, index) in data.metrics"
              :key="index"
              class="col-12 col-lg-6 col-md-6 col-xl-4 mb-2"
            >
              <div class="card h-100">
                <h4 class="card-header">{{ metric.description.replace("Number of", "") }}</h4>
                <div class="card-body row">
                  <div class="col-12 col-xl-6">
                    <h2 class="h4">{{ metric.value }}</h2>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </template>
        <template v-else>
          <div v-if="error" class="display-2-lg">{{ error.message }}</div>
          <div v-else class="display-2">...</div>
        </template>
      </div>
    </div>
  </HourglassLoader>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { apiGet } from "@/api";
import HourglassLoader from "@/components/loader/HourglassLoader";
import { Toast } from "@/utils/notify";

export default {
  name: "Metrics",
  components: { HourglassLoader },
  setup() {
    const loading = ref(false);
    const data = ref(null);
    const error = ref(null);
    const onlinePaymentRef = ref(null);
    const REF_paymentModalButton = ref(null);

    const RefreshBalance = async () => {
      loading.value = true;
      try {
        const response = await apiGet("/admin/misc/dashboard");
        data.value = response.data.data;

        console.log(data.value);
        Toast.fire({
          icon: "success",
          title: "Metrics Fetched",
        }).then();
      } catch (e) {
        error.value = e;
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch wallet balance: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshBalance);

    return {
      RefreshBalance,
      data,
      error,
      loading,
      onlinePaymentRef,
      REF_paymentModalButton,
    };
  },
};
</script>

<style scoped lang="scss">
.card {
  border-radius: 8px;
  color: white;
  padding: 10px;
  position: relative;

  .zmdi {
    color: white;
    font-size: 28px;
    opacity: 0.3;
    position: absolute;
    right: 13px;
    top: 13px;
  }

  .stat {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 8px;
    margin-top: 25px;
    padding: 10px 10px 0;
    text-transform: uppercase;
  }

  .title {
    display: inline-block;
    font-size: 14px;
    padding: 10px 10px 0;
    text-transform: uppercase;
  }

  .value {
    font-size: 28px;
    padding: 0 10px;
  }

  &.blue {
    background-color: #2298f1;
  }

  &.green {
    background-color: #66b92e;
  }

  &.orange {
    background-color: #da932c;
  }

  &.red {
    background-color: #d65b4a;
  }
  &.purple {
    background-color: #b417b4;
  }
}
</style>
