
import { defineComponent } from "vue";
import FiatWalletDetails from "@/components/dashboard/FiatWalletDetails.vue";
import Metrics from "@/components/dashboard/Metrics.vue";
export default defineComponent({
  name: "Dashboard",
  components: {
    Metrics,
    FiatWalletDetails,
  },
});
